<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-card>
          <div class="pb-1 text-center">
            <h2 class="text-primary">
              {{ $t('dataInformations') }}</h2>
          </div>
          <g-form @submit="submition">
            <b-card-body class="border-top-primary">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <g-field
                      :value.sync="init.planId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="planName"
                      field="select"
                      name="name"
                      :disabled="
                        Object.keys(subscription).length !== 0 &&
                          !subscription.paymentPending
                      "
                      :options="plans"
                      :clearable="false"
                      rules="required"
                      label="arabicName"
                      @change="
                        (v) => {
                          checkVal(v);
                        }
                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="pathName !== 'start-calc'"
                  md="6"
                >
                  <b-form-group>
                    <g-field
                      :value.sync="init.tenantId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="tenants"
                      field="select"
                      name="tenants"
                      :clearable="false"
                      :options="tenants"
                      rules="required"
                      label="name"
                      @change="
                        (v) => {
                          subscription.tenants.domainName = v.domainName;
                          getSupscription();
                        }
                      "
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <h4 style="color:red">
                  ملاحظة سعر التأسيس تسدد لمرة واحدة
                </h4>
                <b-col md="8">
                  <g-field
                    id="incorporationPrice"
                    :value.sync="pricing.incorporationPrice"
                    label-text="incorporationPrice"
                    name="incorporationPrice"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- price  -->
                  <g-field
                    id="price"
                    :value.sync="selectedItem.price"
                    label-text="price"
                    name="price"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- months  -->
                  <g-field
                    id="monthes"
                    :value.sync="selectedItem.numberOfMonths"
                    label-text="monthes"
                    name="monthes"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- students  -->
                  <g-field
                    id="dealers"
                    :value.sync="selectedItem.numberOfDealers"
                    label-text="students"
                    name="dealers"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- users  -->
                  <g-field
                    id="users"
                    :value.sync="selectedItem.numberOfUsers"
                    label-text="users"
                    name="users"
                    type="number"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <!-- branches  -->
                  <g-field
                    id="branches"
                    :value.sync="selectedItem.numberOfBranches"
                    label-text="branches"
                    name="branches"
                    type="number"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-card-header class="bg-light-secondary card-header p-1">
                <h3>{{ $t('addtions') }}</h3>
              </b-card-header>
              <b-row>
                <b-col md="6">
                  <!-- branches  -->
                  <g-field
                    id="branches"
                    :value.sync="init.additionalBranchNumer"
                    :disabled="
                      Object.keys(subscription).length !== 0 &&
                        !subscription.paymentPending
                    "
                    label-text="branches"
                    rules="onlyNum"
                    name="maxCountOfUsage"
                  />
                </b-col>
                <b-col
                  md="6"
                  class="m-auto"
                >
                  <b-alert
                    class="mb-0 mt-1"
                    variant="info"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        {{ $t('addMoreBranch')
                        }}<strong>
                          {{ pricing.additionalBranchPrice }}
                          {{ $t('real') }}
                        </strong>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <!-- users  -->
                  <g-field
                    id="users"
                    :value.sync="init.additionalUserNumber"
                    :disabled="
                      Object.keys(subscription).length !== 0 &&
                        !subscription.paymentPending
                    "
                    label-text="users"
                    name="maxCountOfUsage"
                    rules="onlyNum"
                  />
                </b-col>
                <b-col
                  md="6"
                  class="m-auto"
                >
                  <b-alert
                    class="mb-0 mt-1"
                    variant="info"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        {{ $t('addMoreUser') }}
                        <strong>
                          {{ pricing.additionalUserPrice }}
                          {{ $t('real') }}</strong>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-form-group
                  class="col-12 pr-0 d-inline-button"
                  label-cols="12"
                  label-cols-lg="3"
                  label-cols-sm="12"
                  label-size="lg"
                  :label="$t('discountVoucher')"
                  label-for="input-lg"
                >
                  <b-form-input
                    id="input-lg"
                    v-model="discountVoucher"
                    size="lg"
                    class="text-center"
                    :placeholder="$t('discountVoucher')"
                  />
                  <div class="">
                    <b-button
                      variant="outline-primary"
                      :disabled="!discountVoucher"
                      :v-bind:class="
                        validVoucher
                          ? 'btn-outline-success p-1'
                          : 'btn-outline-danger p-1'
                      "
                      @click="validateDiscount(discountVoucher)"
                    >
                      {{ $t('Add') }}
                    </b-button>
                  </div>
                </b-form-group>
              </b-row>
            </b-card-body>
          </g-form>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div class="pb-1 text-center">
            <h2 class="text-primary">
              {{ $t('finalsOfSubscrubtion') }}
            </h2>
          </div>
          <b-card-body class="border-top-primary pb-1">
            <!-- <b-row>
              <b-form-group
                class="col-12 pr-0"
                label-cols="2"
                label-cols-lg="2"
                label-size="lg"
                :label="$t('price')"
                label-for="input-lg"
              >
                <b-form-input
                  id="input-lg"
                  size="lg"
                  class="text-center"
                  :value.sync="selectedItem.price"
                  placeholder="Name"
                  readonly
                />
              </b-form-group>
            </b-row> -->
            <div class="d-none">
              <b-row>
                <b-card-header class="card-header">
                  <h3>{{ $t('subscrubtionData') }}</h3>
                </b-card-header>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- price  -->
                  <g-field
                    id="price"
                    :value.sync="selectedItem.price"
                    label-text="price"
                    name="price"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- monthes  -->
                  <g-field
                    id="monthes"
                    :value.sync="selectedItem.numberOfMonths"
                    label-text="monthes"
                    name="monthes"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- dealers  -->
                  <g-field
                    id="dealers"
                    :value.sync="selectedItem.numberOfDealers"
                    label-text="dealers"
                    name="dealers"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- salesInvoices  -->
                  <g-field
                    id="salesInvoices"
                    :value.sync="selectedItem.numberOfSalesInvoices"
                    label-text="salesInvoices"
                    name="salesInvoices"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- items  -->
                  <g-field
                    id="items"
                    :value.sync="selectedItem.numberOfItems"
                    label-text="items"
                    name="items"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- purchaseInvoices  -->
                  <g-field
                    id="purchaseInvoices"
                    :value.sync="selectedItem.numberOfPurchInvoices"
                    label-text="purchaseInvoices"
                    name="purchaseInvoices"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- assets  -->
                  <g-field
                    id="assets"
                    :value.sync="selectedItem.numberOfFinancialTransactions"
                    label-text="assets"
                    name="assets"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <!-- vouchers  -->
                  <g-field
                    id="vouchers"
                    :value.sync="selectedItem.numberOfVouchers"
                    label-text="vouchers"
                    name="vouchers"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <g-field
                    id="stores"
                    :value.sync="selectedItem.numberOfStores"
                    label-text="stores"
                    name="stores"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <!-- users  -->
                  <g-field
                    id="users"
                    :value.sync="finalUsers"
                    label-text="users"
                    name="users"
                    type="number"
                    disabled
                  />
                </b-col>
                <b-col md="6">
                  <!-- branches  -->
                  <g-field
                    id="branches"
                    :value.sync="finalBranches"
                    label-text="branches"
                    name="branches"
                    type="number"
                    disabled
                  />
                </b-col>
              </b-row>
            </div>
            <b-card-header class="bg-light-secondary card-header p-1">
              <h3>{{ $t('total') }}</h3>
            </b-card-header>
            <b-row class="m-auto pt-3">
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('price') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(selectedItem.price)"
                  placeholder="users"
                  type="number"
                  name="price"
                  disabled
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('incorporationPrice') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(incorporationPrices)"
                  placeholder="incorporationPrice"
                  type="number"
                  name="incorporationPrice"
                  disabled
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('users') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(totalUsers)"
                  placeholder="users"
                  type="number"
                  name="users"
                  disabled
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('branches') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(totalBranches)"
                  placeholder="branches"
                  type="number"
                  name="branches"
                  disabled
                />
              </b-col>
            </b-row>
            <hr>
            <b-row class="m-auto">
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('totalAdds') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(totalAdds)"
                  placeholder="totalAdds"
                  type="number"
                  name="totalAdds"
                  disabled
                />
              </b-col>
            </b-row>
            <hr>
            <b-row class="m-auto">
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('total') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(total)"
                  placeholder="total"
                  type="number"
                  name="total"
                  disabled
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('discount') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="m-auto"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(discount)"
                  placeholder="discount"
                  type="number"
                  name="discount"
                  disabled
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('netBeforeTaxes') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(netBeforeTaxes)"
                  placeholder="netBeforeTaxes"
                  type="number"
                  name="netBeforeTaxes"
                  disabled
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ $t('tax') }}
                </span>
              </b-col>
              <b-col
                cols="12"
                md="9"
              >
                <g-field
                  class="m-auto"
                  :value="fraction(calcTax)"
                  placeholder="tax"
                  type="number"
                  name="tax"
                  disabled
                />
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer class="bg-gradient-primary p-75">
            <b-row class="pl-2">
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder text-nowrap h3 text-white">
                  {{ $t('net') }}
                </span>
              </b-col>
              <b-col
                cols="6"
                md="6"
                class="m-auto"
              >
                <h3 class="text-white">
                  {{ fraction(totalNet) }}
                </h3>
              </b-col>
              <b-col md="3">
                <b-button
                  v-if="(pathName !== 'start-calc' && Object.keys(subscription).length === 0) || subscription.paymentPending"
                  :disabled="!init.planId || totalNet < 0"
                  variant="light"
                  block
                  @click="submition"
                >
                  {{ $t('pay') }}
                </b-button>
                <b-button
                  v-if="pathName !== 'start-calc' && Object.keys(subscription).length !== 0 && !subscription.paymentPending"
                  :disabled="!init.planId || totalNet < 0"
                  variant="light"
                  block
                  @click="() => {
                    saveInit();
                  }"
                >
                  {{
                    this.$t('renewLincesForOneYear', {
                      days: 365,
                    })
                  }}
                </b-button>
                <b-button
                  v-if="pathName === 'start-calc'"
                  :disabled="!init.planId || totalNet < 0"
                  variant="light"
                  block
                  @click="submition"
                >
                  {{ $t('next') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="payForm-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      style="direction: ltr"
      size="lg"
      hide-footer
      :title="$t('pay')"
    >
      <h2 class="text-center mb-2">
        <span class="font-weight-bolder text-nowrap">
          {{ $t('net') }}
        </span>
        : {{ fraction(totalNet) }}
      </h2>
      <form
        style="direction: ltr"
        :action="prodctionUrl"
        class="paymentWidgets"
        data-brands="VISA MASTER AMEX MADA"
      />
      <b-row>
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mx-1"
            variant="secondary"
            @click="closeModal"
          >
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      selectedItem: {
        numberOfBranches: 0,
        numberOfUsers: 0,
      },
      init: {
        tenantId: 0,
        planId: 0,
        additionalUserNumber: 0,
        additionalBranchNumer: 0,
        additionalInvoiceNumber: 0,
        additionalItemNumber: 0,
        discountCodeId: null,
        clientId: 0,
        incorporationPrice: 0
      },
      discountVoucher: '',
      userName: '',
      companyName: '',
      net: 0,
      tax: 0,
      discountObj: {},
      discountPercentage: 0,
      pricing: {},
      plans: [],
      tenants: [],
      userData: JSON.parse(localStorage.getItem('@USER_PROFILE')),
      prodctionUrl: `${document.location.origin}/#/`,
      validVoucher: false,
      pathName: this.$route.name,
      remainingNet: 0,
      subscription: {},
      // totalAdds: 0,
    };
  },
  computed: {
    finalBranches() {
      return (
        this.selectedItem.numberOfBranches + this.init.additionalBranchNumer
      );
    },
    finalUsers() {
      return this.selectedItem.numberOfUsers + this.init.additionalUserNumber;
    },
    totalUsers() {
      return this.init.additionalUserNumber * this.pricing.additionalUserPrice;
    },
    incorporationPrices() {
      if (this.pathName !== 'start-calc' && Object.keys(this.subscription).length !== 0 && !this.subscription.paymentPending) {
       0
      } else {
        return this.pricing.incorporationPrice;
      }
      return 0;
    },
    totalBranches() {
      return (
        this.init.additionalBranchNumer * this.pricing.additionalBranchPrice
      );
    },
    totalAdds() {
      return this.totalUsers + this.totalBranches + this.incorporationPrices;
    },
    total() {
      return this.totalAdds + this.selectedItem.price;
    },
    discount() {
      return this.fraction(
        (parseFloat(this.total) * this.discountPercentage) / 100
      );
    },
    netBeforeTaxes() {
      return this.total - this.discount;
    },
    calcTax() {
      return this.netBeforeTaxes * (this.pricing.taxPercentage / 100);
    },
    totalNet() {
      return this.netBeforeTaxes + this.calcTax; // - this.remaining;
    },
    // remaining() {
    //   return this.remainingNet // - (this.netBeforeTaxes + this.calcTax);
    // }
  },
  mounted() {
    this.getPlans();
    if (this.pathName !== 'start-calc') {
      this.getTenants();
      this.incorporationPrices;
    }
    this.getActivePrice();
  },
  methods: {
    renewLicense() {
      this.update({
        url: 'Subscriptions/renew-subscription',
        id: `${this.subscription.tenants.domainName}/365`,
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        this.$router.push({ name: 'home' });
      });
    },
    getSupscription() {
      this.get({ url: 'Clients/Subscription' }).then((data) => {
        if (Object.keys(data).length !== 0) {
          this.subscription = data;
          this.init.planId = data.plans.id;
          const planData = this.plans.find(
            (val) => val.id === Number(this.init.planId)
          );
          this.checkVal(planData);
          this.init.additionalBranchNumer = Number(data.plans.additionalBranchNumer) || 0;
          this.init.additionalUserNumber = Number(data.plans.additionalUserNumber) || 0;
        } else {
          this.subscription = {};
        }
      });
    },
    submition() {
      if (this.pathName === 'start-calc') {
        this.fristInitObj();
        this.$router.push({ name: 'register' });
      } else {
        this.saveInit();
      }
    },
    checkVal(val) {
      if (val.numberOfDealers === -1) val.numberOfDealers = this.$t('unLimit');
      if (val.numberOfFinancialTransactions === -1) val.numberOfFinancialTransactions = this.$t('unLimit');
      if (val.numberOfItems === -1) val.numberOfItems = this.$t('unLimit');
      if (val.numberOfPurchInvoices === -1) val.numberOfPurchInvoices = this.$t('unLimit');
      if (val.numberOfSalesInvoices === -1) val.numberOfSalesInvoices = this.$t('unLimit');
      if (val.numberOfVouchers === -1) val.numberOfVouchers = this.$t('unLimit');
      this.selectedItem = val;
    },
    validateDiscount(code) {
      this.create({ url: `DiscountCode/${code}/validate` })
        .then((data) => {
          this.doneAlert({
            text: this.$t('doneDiscountWith', {
              value: data.percentage,
            }),
          });
          this.discountObj = data;
          this.discountPercentage = data.percentage;
          this.validVoucher = true;
          this.discountVoucher = '';
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
          this.validVoucher = false;
          this.discountVoucher = '';
          this.discountPercentage = 0;
        });
    },
    getPlans() {
      this.get({ url: 'Plans' }).then((data) => {
        this.plans = data;
        if (this.$route.params.id) {
          const updates = this.plans.find(
            (val) => val.id === Number(this.$route.params.id)
          );
          this.init.planId = Number(this.$route.params.id);
          this.checkVal(updates);
        }
        if (sessionStorage.getItem('INIT_OBJ')) {
          this.init.planId = JSON.parse(
            sessionStorage.getItem('INIT_OBJ')
          ).planId;
          const planData = this.plans.find(
            (val) => val.id === Number(this.init.planId)
          );
          this.checkVal(planData);
          this.init.additionalBranchNumer = Number(
            JSON.parse(sessionStorage.getItem('INIT_OBJ')).additionalBranchNumer
          );
          this.init.additionalUserNumber = Number(
            JSON.parse(sessionStorage.getItem('INIT_OBJ')).additionalUserNumber
          );
        }
      });
    },
    getTenants() {
      this.get({ url: 'Clients/Tenant' }).then((data) => {
        this.tenants = [];
        this.tenants.push(data);
        // if (this.tenants.length === 1) {
        this.init.tenantId = data.id;
        this.getSupscription();
        // this.getRemaining();
        // }
      });
    },
    getActivePrice() {
      this.get({ url: 'PricingPolicy/active' }).then((data) => {
        this.pricing = data;
      });
    },
    // getRemaining() {
    //   this.get({ url: 'Payment/remaining-net' }).then((data) => {
    //     this.remainingNet = data;
    //   });
    // },
    saveInit() {
      if (this.validVoucher === true) {
        this.init.discountCodeId = this.discountObj.id;
      }
      this.init.clientId = this.userData.clientId;
      if (this.pathName !== 'start-calc' && Object.keys(this.subscription).length !== 0 && !this.subscription.paymentPending) {
       0
      } else {
        this.init.incorporationPrice = this.pricing.incorporationPrice;
      }
      this.create({ url: 'Payment/init', data: this.init })
        .then((data) => {
          this.openModal(data);
          localStorage.CHECKOUT_ID = JSON.stringify(data);
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        });
    },
    fristInitObj() {
      if (this.validVoucher === true) {
        this.init.discountCodeId = this.discountObj.id;
      }
      window.sessionStorage.setItem('INIT_OBJ', JSON.stringify(this.init));
      window.sessionStorage.setItem('TOTAL', JSON.stringify(this.totalNet));
    },
    closeModal() {
      this.$refs['payForm-modal'].hide();
    },
    openModal(data) {
      this.$refs['payForm-modal'].show();
      const paymentWidgets = document.createElement('script');
      paymentWidgets.setAttribute(
        'src',
        `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${data}`
      );
      document.head.appendChild(paymentWidgets);
    },
  },
};
</script>
<style>
.d-inline-button > .col {
  display: flex !important;
}

.wpwl-form {
  direction: rtl !important;
}
</style>
